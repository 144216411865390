.App {
  color: white;
  margin: 0;
  padding: 0;
  /* width: 100%;
  height: 100vh; */
  /* display: block; */
  overflow: hidden;
  position: relative;
}

.circle {
  position: absolute;
  overflow: hidden;
  width: 40vh;
  height: 40vh;
  mix-blend-mode: exclusion;
  background-color: white;
  border-radius: 50%;
  z-index: 1;
  animation: circle 35s infinite forwards ease-out;
}
.littlecircle {
  position: absolute;
  overflow: hidden;
  width: 20vh;
  height: 20vh;
  mix-blend-mode: exclusion;
  /* background-color: white; */
  background-color: #22303e;
  border-radius: 50%;
  z-index: 1;
  animation: littlecircle 30s infinite forwards ease-out;
}

@keyframes littlecircle {
  0% {
    transform: translate(120vw, 30vh);
  }
  10% {
    transform: translate(calc(90vw - 20vh), 80vh);
  }
  20% {
    transform: translate(calc(60vw - 20vh), 0vh);
  }
  30% {
    transform: translate(calc(0vw), 40vh);
  }
  40% {
    transform: translate(calc(40vw - 20vh), 80vh);
  }
  50% {
    transform: translate(calc(75vw - 20vh), 0vh);
  }
  60% {
    transform: translate(calc(100vw - 20vh), 10vh);
  }
  70% {
    transform: translate(calc(55vw - 20vh), 80vh);
  }
  80% {
    transform: translate(calc(30vw - 20vh), 0vh);
  }
  90% {
    transform: translate(calc(0vw), 50vh);
  }
  100% {
    transform: translate(calc(100vw - 20vh), 100vh);
  }
}

@keyframes circle {
  0% {
    transform: translate(-40vh, -40vh);
  }
  10% {
    transform: translate(calc(90vw - 40vh), 60vh);
  }
  20% {
    transform: translate(calc(100vw - 40vh), 40vh);
  }
  30% {
    transform: translate(calc(50vw - 40vh), 0vh);
  }
  40% {
    transform: translate(calc(0vw), 30vh);
  }
  50% {
    transform: translate(calc(35vw - 40vh), 60vh);
  }
  60% {
    transform: translate(calc(100vw - 40vh), 10vh);
  }
  70% {
    transform: translate(calc(90vw - 40vh), 0vh);
  }
  80% {
    transform: translate(calc(55vw - 40vh), 60vh);
  }
  90% {
    transform: translate(calc(25vw - 40vh), 0vh);
  }
  100% {
    transform: translate(calc(0vw - 40vh), 70vh);
  }
}
